<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    controller: ReturnType<typeof useModal>;
    isTransparent?: boolean;
    hasCloseButton?: boolean;
  }>(),
  {
    isTransparent: false,
    hasCloseButton: true,
  },
);

const { controller } = toRefs(props);
const { isOpen, close } = controller.value;

const { escape } = useMagicKeys();
if (escape) watch(escape, close);

const modalContentElement = ref();
onClickOutside(modalContentElement, close);
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <Transition
        enter-active-class="transition ease-out duration-200 transform"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200 transform"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-show="isOpen"
          class="fixed z-1000 inset-0 overflow-y-auto bg-black/50 login-modal"
        >
          <div
            class="flex items-center justify-center min-h-screen lg:-mt-3% text-center"
          >
            <Transition
              enter-active-class="transition ease-out duration-500 transform"
              enter-from-class="opacity-0 translate-y-10 scale-95"
              enter-to-class="opacity-100 translate-y-0 scale-100"
              leave-active-class="ease-in duration-200"
              leave-from-class="opacity-100 translate-y-0 scale-100"
              leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
            >
              <div
                v-if="isOpen"
                id="modal-content"
                ref="modalContentElement"
                class="rd text-left m-5"
                :class="
                  isTransparent ? 'bg-transparent' : 'shadow-xl overflow-hidden'
                "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div class="flex justify-end">
                  <i
                    v-if="hasCloseButton"
                    class="i-sl-close inline-block size-5.5 c-white mb-3 cursor-pointer"
                    @click="close"
                  ></i>
                </div>
                <div class="bg-white rd">
                  <slot></slot>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>
